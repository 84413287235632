define(["require", "exports"], function (require, exports) {
    "use strict";
    var _a, _b;
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.isPublicZone = exports.defaultBrowserLanguage = exports.navigationsFromSearchParam = exports.pluginsFromSearchParam = exports.navigationsSetFromSearchParam = exports.configFromSessionStorage = exports.configFromSearchParam = exports.pluginSearchParams = exports.debug = exports.isDebugOn = exports.apiUrl = void 0;
    exports.apiUrl = process.env.API_PATH || "/api";
    exports.isDebugOn = new URLSearchParams(window.location.search).get("debug") === "true";
    const debug = (message, ...optionalParams) => {
        if (exports.isDebugOn) {
            console.info(message, ...optionalParams);
        }
    };
    exports.debug = debug;
    const pluginSearchParams = () => {
        const allowedPluginSearchParams = ["accountId"];
        const pluginSearchParams = {};
        const allSearchParams = new URLSearchParams(window.location.search);
        for (const [key, value] of allSearchParams.entries()) {
            if (allowedPluginSearchParams.some(asp => asp === key)) {
                pluginSearchParams[key] = value;
            }
        }
        return new URLSearchParams(pluginSearchParams);
    };
    exports.pluginSearchParams = pluginSearchParams;
    exports.configFromSearchParam = (() => {
        const config = JSON.parse(new URLSearchParams(window.location.search).get("config"));
        if (config !== null) {
            sessionStorage.setItem("config", JSON.stringify(config));
        }
        return config;
    })();
    exports.configFromSessionStorage = JSON.parse(sessionStorage.getItem("config") || "{}");
    exports.navigationsSetFromSearchParam = (exports.configFromSearchParam !== null && exports.configFromSearchParam.hasOwnProperty("navigationsSet"))
        ? exports.configFromSearchParam["navigationsSet"]
        : exports.configFromSessionStorage.navigationsSet;
    exports.pluginsFromSearchParam = (exports.configFromSearchParam !== null && exports.configFromSearchParam.hasOwnProperty("plugins"))
        ? exports.configFromSearchParam["plugins"]
        : (_a = exports.configFromSessionStorage.plugins) !== null && _a !== void 0 ? _a : [];
    exports.navigationsFromSearchParam = (exports.configFromSearchParam !== null && exports.configFromSearchParam.hasOwnProperty("navigations"))
        ? exports.configFromSearchParam["navigations"]
        : (_b = exports.configFromSessionStorage.navigations) !== null && _b !== void 0 ? _b : [];
    exports.defaultBrowserLanguage = (() => {
        var _a, _b;
        const browserLanguage = (_b = (_a = navigator.languages[0]) !== null && _a !== void 0 ? _a : navigator.language) !== null && _b !== void 0 ? _b : "en";
        return browserLanguage !== "zh-cn" && browserLanguage !== "zh-tw"
            ? browserLanguage.split("-")[0]
            : browserLanguage;
    })();
    const isPublicZone = (environment) => environment === null || environment === void 0 ? void 0 : environment.features.publicZone.map(p => p.path).some(publicPath => window.location.pathname.includes(publicPath.split("/").filter(pps => pps)[0]));
    exports.isPublicZone = isPublicZone;
});
