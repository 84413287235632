define(["require", "exports", "preact/jsx-runtime", "preact/hooks", "rxjs", "@os/os-common/lib/ioc", "@os/os-common/lib/services/TranslationService", "@os/os-components/lib/OsHeader/OsHeader", "@os/os-components/lib/OsHooks/useTranslationFor", "../contexts/recentActivityProvider", "../contexts/userProvider", "../hooks/useMutation", "../hooks/useQuery", "../utils/pluginUrl", "../contexts/searchProvider"], function (require, exports, jsx_runtime_1, hooks_1, rxjs_1, ioc_1, TranslationService_1, OsHeader_1, useTranslationFor_1, recentActivityProvider_1, userProvider_1, useMutation_1, useQuery_1, pluginUrl_1, searchProvider_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.Header = exports.NotificationDataProvider = exports.LanguageDataProvider = void 0;
    const translationService = ioc_1.container.get(TranslationService_1.TranslationService);
    class LanguageDataProvider {
        constructor(save) {
            this.save = save;
        }
        loadLanguages() {
            return (0, rxjs_1.of)([
                { label: "\u4E2D\u6587\uFF08\u7B80\u4F53\uFF09", value: "zh-cn" },
                { label: "\u010Ce\u0161tina", value: "cs" },
                { label: "English\r\n", value: "en" },
                { label: "Fran\u00E7ais\n", value: "fr" },
                { label: "Fran\u00E7ais (Canada)", value: "fr-ca" },
                { label: "Deutsch\n", value: "de" },
                { label: "Magyar", value: "hu" },
                { label: "Italiano", value: "it" },
                { label: "\u65E5\u672C\u8A9E", value: "ja" },
                { label: "\uD55C\uAD6D\uC5B4", value: "ko" },
                { label: "Polski", value: "pl" },
                { label: "Portugu\u00EAs", value: "pt" },
                { label: "Portugu\u00EAs (Brasil)", value: "pt-br" },
                { label: "Rom\u00E2n\u0103", value: "ro" },
                { label: "\u0440\u0443\u0441\u0441\u043A\u0438\u0439", value: "ru" },
                { label: "Espa\u00F1ol\n", value: "es" },
                { label: "Espa\u00F1ol (M\u00E9xico)", value: "es-mx" }
            ]);
        }
        saveLanguage(lang) {
            this.save(lang);
        }
    }
    exports.LanguageDataProvider = LanguageDataProvider;
    class NotificationDataProvider {
        constructor(notifications, environment, invoke, refresh, navigate) {
            this.notifications = notifications;
            this.environment = environment;
            this.invoke = invoke;
            this.refresh = refresh;
            this.navigate = navigate;
        }
        get isHistoryButtonVisible() {
            var _a, _b;
            return (_b = (_a = this.environment) === null || _a === void 0 ? void 0 : _a.features.notificationHistoryEnabled) !== null && _b !== void 0 ? _b : false;
        }
        onHistoryButtonClick() {
            this.navigate({ labelKey: "navItem.notificationHistory", pluginName: "notificationhistory" })();
        }
        loadNotifications() {
            return (0, rxjs_1.of)(this.notifications);
        }
        onNotificationAction(notifId, actionId) {
            return (0, rxjs_1.from)(new Promise((resolve, _) => this.invoke({ notifItemId: notifId, actionId }, resolve)))
                .pipe((0, rxjs_1.tap)(actionResponse => {
                if (actionResponse && !actionResponse.redirectUrl) {
                    this.refresh();
                }
            }));
        }
    }
    exports.NotificationDataProvider = NotificationDataProvider;
    const additionalPreferences = (environment) => {
        const result = [];
        if (environment === null || environment === void 0 ? void 0 : environment.features.preferencesEnabled) {
            result.push({ label: "userMenu.preferences", value: "preferences" });
        }
        if (environment === null || environment === void 0 ? void 0 : environment.features.changeLanguageEnabled) {
            result.push({ label: "userMenu.changeLanguage", value: "language" });
        }
        return result;
    };
    const Header = ({ appName, userLogin, login, logout, navigate, navigations, partnerNavigations, helpLinkTemplate, myProfileLink, contactLink, accessToken, onMenuOpenedChanged, appVersion, environment }) => {
        const { values: { todaysRecentActivities, restRecentActivities } } = (0, hooks_1.useContext)(recentActivityProvider_1.RecentActivityContext);
        const { user, language, setLanguage, isLanguageSelectorOpen, setIsLanguageSelectorOpen } = (0, hooks_1.useContext)(userProvider_1.UserContext);
        const { setSearchValue } = (0, hooks_1.useContext)(searchProvider_1.SearchContext);
        const headerTranslationBundle = {
            breadcrumbHomeLabel: "",
            signInText: "userMenu.signIn",
            signOutText: "userMenu.signOut",
            languageDialogTitle: "userMenu.languageDialog.title",
            languageDialogListLabel: "userMenu.languageDialog.listLabel",
            languageDialogApply: "apply",
            languageDialogCancel: "cancel",
            preferencesText: "userMenu.preferences",
            getHelpText: "userMenu.getHelp",
            navigationDashboardLabel: "navItem.Explore",
            navigationRecentlyVisitedLabel: "navItem.RecentlyVisited",
            navigationEcosystemLabel: "navItem.Ecosystem",
            noRecentlyVisitedTitle: "recentActivity.noEntryTitle",
            noRecentlyVisitedLabel: "recentActivity.noEntryLabel",
            currentOrganizationText: "userMenu.tile.currentOrganization",
            membershipInfoText: "userMenu.tile.membershipInfo",
            membershipExpirationLabel: "userMenu.tile.membershipExpirationLabel",
            membershipTracksLabel: "userMenu.tile.membershipTracksLabel",
            partnerAssistanceLabel: "navItem.partnerAssistance",
            partnerAssistanceTitle: "partnerAssistance.partnerAssistanceTitle",
            partnerAssistanceHelp: "partnerAssistance.partnerAssistanceHelp",
            searchPlaceholder: "searchPlaceholder"
        };
        const callUsText = (0, useTranslationFor_1.useTranslationFor)("userMenu.callUs");
        const languageAwareHelpLink = helpLinkTemplate === null || helpLinkTemplate === void 0 ? void 0 : helpLinkTemplate.replace("${language}", language);
        const helpLink = (accessToken && languageAwareHelpLink.includes("/app/helpcenter")) ? (0, pluginUrl_1.forceSso)(languageAwareHelpLink) : languageAwareHelpLink;
        const userMenuOptions = {
            preferences: [
                { label: "userMenu.myProfile", value: "myprofile", path: myProfileLink },
                ...additionalPreferences(environment)
            ],
            getHelp: !(environment === null || environment === void 0 ? void 0 : environment.features.getHelpDisabled)
                ? [
                    { label: "userMenu.helpCenter", value: "helpCenter", path: helpLink },
                    { label: "userMenu.contactUsOnline", value: "contact", path: contactLink },
                    { label: `${callUsText} 1-866-201-9198`, value: "call", path: "tel:+1-866-201-9198" }
                ]
                : undefined,
            getHelpIcon: (environment === null || environment === void 0 ? void 0 : environment.features.getHelpDisabled) && helpLink ? { label: "userMenu.helpCenter", value: "helpCenter", path: helpLink } : undefined,
            profile: user === null || user === void 0 ? void 0 : user.profile
        };
        const languageDataProvider = (0, hooks_1.useMemo)(() => new LanguageDataProvider(setLanguage), [setLanguage]);
        const { result: notifications, refresh: refreshNotifications } = (0, useQuery_1.useNotificationQuery)(accessToken);
        const { invoke } = (0, useMutation_1.useNotificationActionMutation)(accessToken);
        const notificationDataProvider = (0, hooks_1.useMemo)(() => { var _a; return new NotificationDataProvider((_a = notifications === null || notifications === void 0 ? void 0 : notifications.items) !== null && _a !== void 0 ? _a : [], environment, invoke, refreshNotifications, navigate); }, [notifications, environment, invoke, refreshNotifications, navigate]);
        const notificationOptions = {
            dataProvider: notificationDataProvider,
            translationBundle: {
                drawerTitle: "notifications.drawerTitle",
                closeButtonLabel: "",
                viewOptionsTitle: "notifications.viewOptionsTitle",
                criticalTitle: "notifications.criticalTitle",
                highTitle: "notifications.highTitle",
                mediumTitle: "notifications.mediumTitle",
                lowTitle: "notifications.lowTitle",
                viewHistoryLabel: "notifications.viewArchiveLabel"
            }
        };
        const onLanguageSelectorStateChange = (evt) => {
            setIsLanguageSelectorOpen(evt.value);
        };
        const setSearchAndNavigate = (sv) => {
            setSearchValue(sv);
            navigate({ labelKey: "Search", pluginName: "search" })();
        };
        const recentAcitivityNavItems = (0, hooks_1.useMemo)(() => {
            const result = [];
            const mapRecentActivityToNavigationItem = (ra) => {
                var _a;
                const labelKey = translationService.t((_a = ra.label) !== null && _a !== void 0 ? _a : "")() + (ra.entityId ? ` #${ra.entityId}` : "");
                const subLabel = new Date(ra.creationDate).toLocaleTimeString([language]);
                const url = new URL(ra.url, window.location.origin);
                const appendPath = `/${url.pathname.split("/").filter(Boolean).slice(1).join("/")}`;
                if (ra.plugin && url.origin === window.location.origin) {
                    return {
                        labelKey,
                        pluginName: ra.plugin,
                        appendPath: appendPath !== "/" ? appendPath : undefined,
                        subLabel
                    };
                }
                return {
                    labelKey,
                    path: ra.url,
                    subLabel
                };
            };
            if ((todaysRecentActivities === null || todaysRecentActivities === void 0 ? void 0 : todaysRecentActivities.length) > 0) {
                result.push({
                    labelKey: "navItem.today",
                    path: "",
                    children: todaysRecentActivities.map(mapRecentActivityToNavigationItem)
                });
            }
            Object.keys(restRecentActivities).sort((a, b) => parseInt(b) - parseInt(a)).map(dateValue => {
                result.push({
                    labelKey: new Date(parseInt(dateValue)).toLocaleDateString([language], { year: "numeric", month: "short", day: "numeric" }),
                    path: "",
                    children: restRecentActivities[dateValue].map(mapRecentActivityToNavigationItem)
                });
            });
            return result;
        }, [todaysRecentActivities, restRecentActivities, language]);
        const homeNavigationItem = {
            labelKey: "navItem.Dashboard",
            pluginName: "dashboard"
        };
        return ((0, jsx_runtime_1.jsx)(OsHeader_1.OsHeader, { appName: appName, appVersion: appVersion, navigationMenuOptions: navigations && { navigations, recentlyVisited: recentAcitivityNavItems, partnerAssistance: partnerNavigations }, navigate: navigate, userName: userLogin, userEmail: user === null || user === void 0 ? void 0 : user.email, userMenuOptions: userMenuOptions, translationBundle: headerTranslationBundle, login: login, logout: logout, languageDataProvider: languageDataProvider, notificationOption: notifications && notificationOptions, homeNavigationItem: homeNavigationItem, onMenuOpenedChanged: onMenuOpenedChanged, isLanguageSelectorOpen: isLanguageSelectorOpen, onLanguageSelectorOpenStateChanged: onLanguageSelectorStateChange, isSearchEnabled: (environment === null || environment === void 0 ? void 0 : environment.features.searchEnabled) && userLogin !== "", onSearchValueChanged: detail => setSearchAndNavigate(detail.searchValue) }));
    };
    exports.Header = Header;
});
